import axios from 'axios';
import { LatLng } from 'leaflet';
import authHeader from './auth-header';
const API_URL = process.env.REACT_APP_API_URL || 'http://maps-api.firemap.org/api/';
class MapPredictionService {
  getSmokeDayList() {
    return axios.get(API_URL + 'smokemap-list', { headers: authHeader() });
  }
  getSmokeDayTimeList() {
    return axios.get(API_URL + 'smokemap-datetime-list', { headers: authHeader() });
  }
  getFirePerimeterList() {
    return axios.get(API_URL + 'fire-perimeter-list', { headers: authHeader() });
  }
  getFireSmokeMap(date: string) {
    return axios.get(API_URL + 'smokemap', { headers: authHeader(), params: {date: date} });
  }
  getPeriodFireSmokeMap(startDate: string, endDate: string) {
    return axios.get(API_URL + 'smokemap-dates', { headers: authHeader(), params: {start_date: startDate, end_date: endDate} });
  }
  getHourFireSmokeMap(dateTime: string) {
    return axios.get(API_URL + 'smokemap-hour', { headers: authHeader(), params: {datetime: dateTime} });
  }
  getNoaaFireSmokeMap(date: string) {
    return axios.get(API_URL + 'smokemap-png-noaa', { headers: authHeader(), params: {date: date} });
  }
  getBlueSkyFireSmokeMap(date: string) {
    return axios.get(API_URL + 'smokemap-png', { headers: authHeader(), params: {date: date} });
  }
  getFirePerimeterMap(date: string, limit=200) {
    return axios.get(API_URL + 'fire-perimeter-new-map', { headers: authHeader(), params: {date: date, limit: limit} });
  }
  getFirePerimeterPredictionMap(date: string, limit=200) {
    return axios.get(API_URL + 'ai-model-data', { headers: authHeader(), params: {date: date, limit: limit} });
  }
  getFireActiveMap(date: string) {
    return axios.get(API_URL + 'fire-active-map', { headers: authHeader(), params: {date: date} });
  }
  getFirePointMap(date: string) {
    return axios.get(API_URL + 'fire-point-map', { headers: authHeader(), params: {date: date} });
  }
  getFireWildlandMap(date: string) {
    return axios.get(API_URL + 'fire-wildland-map', { headers: authHeader(), params: {date: date} });
  }
  getInciwebData(inciwebTitle: string) {
    return axios.get(API_URL + 'show-inciweb-incident', { headers: authHeader(), params: {incident_title: inciwebTitle} });
  }
  getInciwebOverview(date: string) {
    return axios.get(API_URL + 'inciweb-overview', { headers: authHeader(), params: {date: date} });
  }
  getUSAWind(date: string, discreteness = 0) {
    return axios.get(API_URL + 'wind', { headers: authHeader(), params: {date: date, discreteness: discreteness} });
  }
  getWindyCams(northLat: number, eastLon: number, southLat: number, westLon: number, zoom: number) {
    const params = {
      northLat: northLat,
      eastLon: eastLon,
      southLat: southLat,
      westLon: westLon,
      zoom: zoom - 1
    };
    return axios.get(API_URL + 'wind-cams-map-v3', { headers: authHeader(), params: params });
  }
  getUSABarometric(date: string, discreteness = 0) {
    return axios.get(API_URL + 'barometric', { headers: authHeader(), params: {date: date, discreteness: discreteness} });
  }
}
export default new MapPredictionService();